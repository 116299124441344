const paginateGallery = (
  page = 1,
  { hero, body },
  size = 4,
  firstIndex = 1,
) => {
  const gallery = hero.find(({ type }) => type === 'gallery')

  const imageArray = gallery?.data?.gallery?.slice(firstIndex)
  const end = page * size
  const start = end - size
  const images = (imageArray || body)
    .filter(({ type }) => type === 'image')
    .map((img, i) => {
      img.data.no = i + 1
      return img
    })
  const nonImages = body.filter(({ type }) => type !== 'image')

  return {
    body: [...nonImages, ...images.slice(start, end)],
    pagination: {
      currentPage: parseInt(page),
      totalPage: Math.ceil(images.length / size),
    },
  }
}

const paginateContentWithImage = (
  page = 1,
  { hero, body },
  size,
) => {

  const contentWithIndex = body.map((item, i) => {
    item.data.no = i
    return item
  })

  const headings = contentWithIndex.filter(({type}) => type === 'H2')
  const contentBlocks =  headings.map((heading, i) => {
    if(i < headings.length - 1 && i === 0) {
      return contentWithIndex.slice(0,headings[0]?.data?.no)
    }
    if(i < headings.length - 1 && i > 0) {
      return contentWithIndex.slice(headings[i -1]?.data?.no,heading?.data?.no)
    } else {
      return contentWithIndex.slice(heading?.data?.no,contentWithIndex.length)
    }
  })

  size = contentBlocks.length;

  const getHero = (hero) => {
    return (hero && page === 1  ? hero : []);
  }

  const getContentBlocks = (contentBlocks, page) => contentBlocks[page - 1] || [];


  return {
    body: [...getHero(hero),...getContentBlocks(contentBlocks, page)],
    pagination: {
      currentPage: parseInt(page),
      totalPage: Math.ceil (size),
    },
  }
}

const photoArticle = (data, req) => {
  if (
    data.type === 'article' &&
    data.articleTypes.some(({ name }) => /photo gallery/i.test(name))
  ) {
    const pageSize = 1;
    const firstIndex = 0;
    return {
      ...data,
      ...paginateGallery(req.query.page, data, pageSize, firstIndex),
    }
  }
  return data
}

export default photoArticle

export const contentArbitrageArticle = (data, req) => {
  if (
    data.type === 'article' &&
    data.articleTypes.some(({ name }) => /content arbitrage/i.test(name))
  ) {
    const pageSize = 1;
    const firstIndex = 0;
    return {
      ...data,
      ...paginateContentWithImage(req.query.page, data, pageSize, firstIndex),
    }
  }
  return data
}
