import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { device } from '../../../config/theme/Styles';

const ParagraphWrapper = styled.div`
  p {
    color: ${props => props.theme.lightContrast};
    text-align: left;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.01em;
    margin: 20px 0;
    font-family: ${props => props.theme.fontFamily.primaryFont};
    @media ${device.laptop} {
      font-size: 20px;
      line-height: 30px;
      .articletype-video-showcase & {
        font-size: 16px;
        line-height: 120%;
      }
    }
    a {
      color: ${props => props.theme.secondaryColor};
    }
  }
  ul {
    li{
      color: ${props => props.theme.lightContrast};
      margin-bottom: 10px;
      a {
        text-decoration: none;
        color: ${props => props.theme.linkColor};
        font-weight: bold;
      }
    }
  }
  h1,h2,h3,h5,h6,strong {
    font-family: ${props => props.theme.fontFamily.lightContrast};
  }
  h4 {
   font-family: ${props => props.theme.fontFamily.primaryFont};
   font-size: 20px;
    :before {
      content: "";
      display: block;
      width: 50px;
      border-radius: 30px;
      margin-bottom: 8px;
      border-top: 4px solid ${props => props.theme.secondaryColor};
    }
  }
  h2 a {
    color: ${props => props.theme.secondaryColor};
    &:hover {
      color: ${props => props.theme.lightContrast};
    }
  }
`;

const Markup = ({ data: { markup } }) => {
  return (
    <ParagraphWrapper>
      <excessremovablewrapper dangerouslySetInnerHTML={{ __html: markup }} />
    </ParagraphWrapper>
  );
};

Markup.propTypes = {
  data: PropTypes.any.isRequired,
};

export default Markup;
