import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {device} from '../../../config/theme/Styles';


const ButtonWrapper = styled.div`
  display: flex;
  margin: 20px 0;
  flex-wrap: nowrap;
`

const NextButton = styled.a`
  height: 60px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  text-decoration: none;
  display: flex;
  flex-grow: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${props => props.theme.fontColor};
  background-color: ${props => props.theme.secondaryColor};
  @media ${device.mobileM} {
    font-size: 22px;
  }
`

const GalleryArbitragePagination = styled(
  ({ path, currentPage = 0, totalPage = 0, className }) => {
    const blogCurrentPage = currentPage

    const nextPage = blogCurrentPage + 1

    const baseLink = `${path}?page=`
    const nextPageLink = `${baseLink}${nextPage}`
    return (
      <div className={className}>
        <ButtonWrapper>
          {blogCurrentPage < totalPage && (
            <NextButton href={nextPageLink}>Next</NextButton>
          )}
        </ButtonWrapper>
      </div>
    )
  },
)``

GalleryArbitragePagination.propTypes = {
  path: PropTypes.string.isRequired,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
}

export default GalleryArbitragePagination
