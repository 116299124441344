import React from 'react'
import PropTypes from 'prop-types';
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import JPThemeProvider from '../../../services/JPThemeProvider'

const ThemeProvider = ({ children }) => {
  return (
    <StyledThemeProvider theme={new JPThemeProvider().get()}>
      {children}
    </StyledThemeProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.element,
};

export default ThemeProvider