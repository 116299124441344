import React from 'react'
import styled from 'styled-components'
import {device} from '../../../config/theme/Styles';

const srcSet = [320, 800]
const GalleryArbitrageImage = styled(
  ({ className, title, description, caption, localCaption,  url }) => {
    const urlWithQuestionMark = /\?/.test(url) ? `${url}&` : `${url}?`
    return (
      <div className={className}>
        <div className={'caption-gallery'}>
          <h1 className='description'>{description || title}</h1>
        </div>
        <div className={'img-gallery'}>
          <img
            src={
              urlWithQuestionMark +
              'crop=61:45,smart&width=' +
              srcSet[srcSet.length - 1]
            }
            fetchpriority={'high'}
            alt={caption}
            srcSet={srcSet
              .map(
                (size) =>
                  urlWithQuestionMark +
                  `crop=61:45,smart&width=${size} ${size}w`,
              )
              .join(', ')}
          />
        </div>
        <div className={'caption-gallery'}>
          {caption ? <span dangerouslySetInnerHTML={{ __html: caption }}/> : localCaption ? <span dangerouslySetInnerHTML={{ __html: localCaption }}/> : null}
        </div>
      </div>
    )
  },
)`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  .caption-gallery {
    flex-grow: 1;
    p {
      font-size: 18px;
    }
    h1 {
      font-size: 20px;
      line-height: 1.28;
      letter-spacing: 0.6px;
      margin-top: 0;
      word-break: break-word;
      font-weight: 600;
      @media ${device.laptop} {
        font-size: 25px;
      }
    }
  }
  .img-gallery {
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }
`

export default GalleryArbitrageImage
