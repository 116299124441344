export const vidaZooScript = `
  <script defer src="https://static.vidazoo.com/basev/vwpt.js" data-widget-id="64e1dc800b665780892ab847" data-on-widget-ad-impression="onVidazooWidgetAdImpressionCallback"></script>
  <script>
    function onVidazooWidgetAdImpressionCallback(cpm, info) {
        try {
            var highestPreBid_partner = "video_unknown";
            switch (info.adType) {
                case "ima":
                    highestPreBid_partner = "video_AdX";
                    break;
                case "marketplace":
                    highestPreBid_partner = "video_Vidazoo";
                    break;
                case "pre_bid":
                    highestPreBid_partner = "video_" + info.bidderCode;
                    break;
                default:
                    break;
            }
            var data = {
                source: "video",
                slotId: "vidazoo_video",
                adUnitPath: "vidazoo_video",
                highestPreBid: info.netCpm - info.adServingFee,
                highestPreBid_partner,
                mediaType: "vidazoo_video",
                creative_width: info.width,
                creative_height: info.height,
                preBidWon: true,
                timeToRespond: null,
            };
            window.assertive.logCustomImpression(data);
        } catch (error) {
            console.error("Couldn't log custom impression", error);
        }
    }
  </script>
`