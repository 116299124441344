import { themes } from '../config/theme/Styles';

class JPThemeProvider {
  constructor(domain) {
    this.domain = domain;
  }

  get() {
    if (typeof themes[this.domain] !== 'undefined') {
      return Object.assign({}, themes[this.domain]);
    }

    return Object.assign({}, themes['default']);
  }
}

export default JPThemeProvider;
