/*globals JSGlobals*/

const dataLayer = () => {
  /**
   * if pageType === 'image' just send articleId (from url (ie. 371959), not the hash (ie. 9365e9a3-32a3-40fe-8aeb-a55b67f01535))
   * if pageType === 'video' send articleId (from url (ie. 371945), not the hash (ie. ca4b7e3a-f8ff-4b91-a2a7-f392be861c90)) AND videoId (ie. Fk1mJKMP)
   */
  window.pixelsLayer = window.pixelsLayer || [];
  window.pixelsLayer.push({
    id: JSGlobals.articleId,
    heroVideoId: JSGlobals.videoId,
  });
};

export const initPixelsAnalyticsScript = () => {
  if (JSGlobals.pageType !== 'image' && JSGlobals.pageType !== 'video') {
    //only interested in image and video articles
    return;
  }
  dataLayer();
  if (JSGlobals.mode === 'development') {
    return;
  }
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== 'pixelsLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://pub.pixels.ai/pixels-layers-lib.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'pixelsLayer', 'newschain');
};
