/*globals PARSELY */
import { jsLoader } from './fileLoaders';

export const initParselyAnalytics = async () => {
  window.PARSELY = (await window.PARSELY) || {
    autotrack: false,
    onReady: function () {
      PARSELY.beacon.trackPageView();
    },
    // TypeError: 'arguments', 'callee', and 'caller' cannot be accessed in this context
    // Same error on JPI
  };

  jsLoader([
    {
      src: `//cdn.parsely.com/keys/${window.JSGlobals.domain}/p.js`,
      id: 'parsely-cfg',
    },
  ]);
};
