/*globals JSGlobals*/
import { setCookie } from './settings.js';
import bsAnalyticsInit from './modules/bsAnalytics';
import { initGTMScript } from '../../util/gtm';
import { initParselyAnalytics } from '../../util/parselyAnalytics';
import { renderIslands } from '@brightsites/island-hydration';
import GalleryArbitrageItems from './components/GalleryArbitrageItems.js';
import Twitter from '../../component/library/Article/Embeds/Twitter.js';
import ThemeProvider from './providers/ThemeProvider.js';
import { initPixelsAnalyticsScript } from '../../util/pixels-analytics.js';
import { twitterWidget } from '../../util/twitterWidget.js';

window.JSGlobals = window.JSGlobals || {
  mode: 'development',
};

const isTabletQuery = window.matchMedia('(min-border: 768px)');

if (isTabletQuery.matches) {
  /** Chain toggle **/
  const toggles = document.querySelectorAll('.chain-toggle');
  toggles.forEach((el) => {
    let chainRow = el.parentElement;
    let chainRowBody = chainRow.querySelector('.section-body');
    const collapsedHeight = chainRowBody.offsetHeight - 4 + 'px';
    chainRow.classList.add('collapsible');
    const expandedHeight = chainRowBody.offsetHeight + 'px';
    chainRowBody.style.maxHeight = collapsedHeight;
    chainRowBody.style.overflow = 'hidden';
    chainRow.classList.add('collapsible');
    el.addEventListener('click', (e) => {
      if (chainRowBody.style.maxHeight === collapsedHeight) {
        chainRow.classList.remove('collapsed');
        chainRow.classList.add('expanded');
        chainRowBody.style.maxHeight = expandedHeight;
      } else {
        chainRow.classList.add('collapsed');
        chainRow.classList.remove('expanded');
        chainRowBody.style.maxHeight = collapsedHeight;
      }
    });
  });
}

document.querySelectorAll('#countryOption a').forEach((el) => {
  el.addEventListener('click', function (e) {
    const link = e.currentTarget;
    const locale = link.getAttribute('data-locale');
    if (locale) {
      setCookie('Locale', locale);
    }
  });
});

// Toggle class for hamburger menu
document.querySelectorAll('.hamburger-menu').forEach((menu) => {
  menu.addEventListener('click', () => {
    document.body.classList.toggle('full-menu-visible');
  });
});

bsAnalyticsInit();
initGTMScript(); // Google Tag Manager
initPixelsAnalyticsScript();
initParselyAnalytics();
twitterWidget();

(() => {
  renderIslands({
    GalleryArbitrageItems: {
      Component: GalleryArbitrageItems,
      Provider: ThemeProvider,
    },
    Twitter: {
      Component: Twitter,
      Provider: ThemeProvider,
    },
  });
})();
