import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types';

/** This function acts as an HOC to wrap around your markup, which is passed in as {{children}} with an "any" type
 *  (to cover all scenarios). There are two listeners:
 *  @onTopNotVisible is a function that will take a handler to fire when the top of this HOC is scrolled out of view.
 *  @onBottomVisible is a function that will take a handler to fire when the bottom of this HOC is scrolled into view.
 *  @topISOptions are optional properties {{IntersectionObserverOptions}} that you can pass in to control how the top threshold
 *  is measured. Otherwise, the defaults will be in place.
 *  @bottomISOptions are optional properties {{IntersectionObserverOptions}} that you can pass in to control how the bottom threshold
 *  is measured. Otherwise, the defaults will be in place.
 *
 *  type IntersectionObserverOptions:
 *  @root - the element that is used as the viewport for checking visibility of the target. Must be the ancestor of the target.
 *  Defaults to the browser viewport if not specified or if "null".
 *  @rootMargin - margin around the root. Can have values similar to the CSS margin property, e.g. "10px 20px 30px 40px" (top, right, bottom, left).
 *  The values can be percentages. This set of values serves to grow or shrink each side of the root element's bounding box before computing intersections.
 *  Defaults to all zeros.
 *  @threshold - Either a single number or an array of numbers which indicate at what percentage of the target's visibility the observer's callback should
 *  be executed. If you only want to detect when visibility passes the 50% mark, you can use a value of 0.5. If you want the callback to run every time
 *  visibility passes another 25%, you would specify the array [0, 0.25, 0.5, 0.75, 1]. The default is 0 (meaning as soon as even one pixel is visible,
 *  the callback will be run). A value of 1.0 means that the threshold isn't considered passed until every pixel is visible.
 */

export const EndlessScrollListener = ({
  onTopNotVisible,
  onBottomVisible,
  children,
  // topISOptions,
  bottomISOptions,
}) => {
  // const topRef = useRef(null)
  const bottomRef = useRef(null)

  useEffect(() => {
    // const topObserver = new IntersectionObserver(([entry]) => {
    //   if (!entry?.isIntersecting) {
    //     onTopNotVisible()
    //   }
    // }, topISOptions && topISOptions)
    const bottomObserver = new IntersectionObserver(([entry]) => {
      if (entry?.isIntersecting) {
        onBottomVisible()
      }
    }, bottomISOptions && bottomISOptions)

    // if (topRef.current) {
    //   topObserver.observe(topRef.current)
    // }
    if (bottomRef.current) {
      bottomObserver.observe(bottomRef.current)
    }

    return () => {
      // if (topRef.current) {
      //   topObserver.unobserve(topRef.current)
      // }
      if (bottomRef.current) {
        bottomObserver.unobserve(bottomRef.current)
      }
    }
  }, [onTopNotVisible, onBottomVisible])

  return (
    <div>
      {children}
      <div ref={bottomRef} />
    </div>
  )
}

EndlessScrollListener.propTypes = {
  children: PropTypes.element,
  onTopNotVisible:PropTypes.func,
  onBottomVisible:PropTypes.func,
  bottomISOptions: PropTypes.any,
};


export default EndlessScrollListener
