export const setCookie = function(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = 'expires='+ d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

const feeds = document.querySelectorAll('.feed-list li');
const pubs = document.querySelectorAll('.pub-list li');
const clear = document.getElementById('clear-setting');

if (clear) {
  clear.addEventListener('click', function () {
    setCookie('feed', '');
    setCookie('publication', '');
  });
}

if (feeds) {
  feeds.forEach(element => {
    element.addEventListener('click', function (e) {
      setCookie('feed', e.target.getAttribute('data-value'));
      location.href = '/'
    })
  })
}

if (pubs) {
  pubs.forEach(element => {
    element.addEventListener('click', function (e) {
      setCookie('publication', e.target.getAttribute('data-value'));
      location.href = '/'
    })
  })
}


