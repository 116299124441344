import React from 'react';
import styled from 'styled-components';
import Markup from './Markup';
import GalleryArbitrageImage from './GalleryArbitrageImage';
import GalleryArbitragePagination from './GalleryArbitragePagination';
import { vidaZooScript } from './Embeds/VidazooScript';

const Content = styled.div``;

const GalleryArbitrage = styled(
  ({
    className,
    data,
    article,
    configuration,
    cleanAmp,
    isInfiniteScroll,
    isIndex,
  }) => {
    const content = data.map((comp, index) => {
      switch (comp.type) {
        case 'markup':
          return (
            <Markup
              key={comp.type + index}
              isSponsored={false}
              data={comp.data}
            />
          );
        case 'H2':
          return (
            <Markup
              key={comp.type + index}
              isSponsored={false}
              data={comp.data}
            />
          );
        case 'moreFrom':
          return null;
        case 'image':
          return [
            <GalleryArbitrageImage
              cleanAmp={cleanAmp}
              key={comp.type + index}
              {...comp.data}
              article={article}
              configuration={configuration}
            />,
          ];
        default:
          console.log(`Undefined: ${comp.type}`);
          return null;
      }
    });

    content.splice(
      3,
      0,
      <div
        key="vidazoo-script"
        dangerouslySetInnerHTML={{ __html: vidaZooScript }}
      />,
    );

    return (
      <Content className={'article-content ' + className}>
        {content}
        {!isInfiniteScroll && (
          <GalleryArbitragePagination
            path={article.path}
            currentPage={article.pagination.currentPage}
            totalPage={article.pagination.totalPage}
          />
        )}
      </Content>
    );
  },
)``;

export default GalleryArbitrage;
