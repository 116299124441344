/*globals JSGlobals*/

const dataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'trackingIdAvailable',
    trackingId: 'UA-145003099-1',
  });
};

export const initGTMScript = () => {
  dataLayer();
  if (JSGlobals.mode === 'development') {
    return;
  }

  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-KDQH5G6');
};
