import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GalleryArbitrage from '../../../component/library/Article/GalleryArbitrage';
import EndlessScrollListener from '../utils/endless-scroll-listener';
import { contentArbitrageArticle } from '../../../services/paginateGallery';
import { setAdSlotIncrement } from '../../../util/getSlotIncrement';

const ItemContainer = styled.div``;

// Object with numbers instead of enum
const InfiniteScrollState = {
  Initialising: 0,
  Idle: 1,
  Loading: 2,
  Error: 3,
  End: 4,
};

const endlessScrollListenerBottomOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0.5,
};

const GalleryArbitrageItems = ({ article, configuration, cleanAmp }) => {
  const [fullArticle, setFullArticle] = useState();
  const [infiniteScrollState, setInfiniteScrollState] = useState(
    InfiniteScrollState.Initialising,
  );
  const [pagesToDisplay, setPagesToDisplay] = useState([]);
  const [page, setPage] = useState(1);

  const getNextPageData = (pageI) => {
    setInfiniteScrollState(InfiniteScrollState.Loading);

    try {
      setInfiniteScrollState(InfiniteScrollState.Idle);
      return contentArbitrageArticle(fullArticle, { query: { page: pageI } });
    } catch (err) {
      console.error(err);
      setInfiniteScrollState(InfiniteScrollState.Error);
      return null;
    }
  };

  const handleBottomVisible = () => {
    if (infiniteScrollState !== InfiniteScrollState.Idle) {
      return;
    }

    setInfiniteScrollState(InfiniteScrollState.Loading);

    setAdSlotIncrement(page + 1);
    setPage(page + 1);

    setPagesToDisplay((pagesToDisplay) => [
      ...pagesToDisplay,
      getNextPageData(page + 1),
    ]);

    // Trigger assertive yield (AY) new page view
    window?.assertive?.nextPage();

    setInfiniteScrollState(InfiniteScrollState.Idle);
  };

  useEffect(() => {
    fetch(`/api/article?domain=${configuration.domain}&path=${article.path}`)
      .then(async (res) => {
        const data = await res.json();
        setFullArticle(data);
      })
      .catch((err) => {
        console.error(err);
        setInfiniteScrollState(InfiniteScrollState.Error);
      });
  }, []);

  return (
    <EndlessScrollListener
      bottomISOptions={endlessScrollListenerBottomOptions}
      onBottomVisible={handleBottomVisible}
    >
      {pagesToDisplay.length &&
        pagesToDisplay.map(({ body }, index) => {
          return (
            <ItemContainer key={index} data-id={index}>
              <GalleryArbitrage
                article={article}
                data={body}
                configuration={configuration}
                cleanAmp={cleanAmp}
                isInfiniteScroll={true}
                isIndex={index}
              />
            </ItemContainer>
          );
        })}
    </EndlessScrollListener>
  );
};

GalleryArbitrageItems.propTypes = {
  article: PropTypes.object.isRequired,
  configuration: PropTypes.object.isRequired,
  cleanAmp: PropTypes.bool,
};

export default GalleryArbitrageItems;
