import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  min-height: 400px;
  clear: both;
`;

const Twitter = ({ data }) => {
  const tweetId = data.id ? data.id : data.url.split('/').pop().split('?')[0];
  const className = `tweet-${tweetId}`;

  useEffect(() => {
    const renderTweet = () => {
      window.twttr.widgets.createTweet(
        tweetId,
        document.querySelector(`.${className}`),
        {
          align: 'center',
          conversation: data.isConversation,
        },
      );
    };

    const twttrInterval = setInterval(() => {
      if (
        window.twttr &&
        window.twttr.widgets &&
        window.twttr.widgets.createTweet
      ) {
        clearInterval(twttrInterval);
        renderTweet();
      }
    }, 500);

    return () => {
      clearInterval(twttrInterval);
    };
  }, [className, data, tweetId]);

  return (
    <Wrapper className={'twitter-embed'}>
      <div className={className}></div>
    </Wrapper>
  );
};

Twitter.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Twitter;
