import { isElementInViewport } from './domUtil';

const trackArticleComplete = () => {
  const bottomArticle = document.getElementById('bottomArticle');
  if (!bottomArticle) return;
  window.bsAnalytics = window.bsAnalytics || [];

  let completed = false;
  const listener = () => {
      if (!completed) {
        window.requestAnimationFrame(function () {
          if (isElementInViewport(bottomArticle)) {
            window.bsAnalytics.push({ event: 'article-complete'})
            window.removeEventListener('scroll', listener);
          }
          completed = false;
        });
        completed = true;
      }
    };
  window.addEventListener('scroll', listener)
}

const trackSocialShare = () => {
  window.bsAnalytics = window.bsAnalytics || [];
  document.querySelectorAll('amp-social-share, amp-facebook-like')
    .forEach((element) => {
      element.addEventListener('click', function () {
         window.bsAnalytics.push({ event: 'article-social-shared'})
      })
    })
}

export default () => {
  trackSocialShare();
  trackArticleComplete();
}