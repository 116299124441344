/**
 * Gets and sets the value of the ad slots when they are incremented on the client-side (infinite scroll)
 */
let adIncrement = 0;
export const getAdSlotIncrementAppendix = () =>
  adIncrement > 0 ? `-increment-${adIncrement}` : '';
export const getAdSlotIncrement = () => adIncrement;
export const setAdSlotIncrement = (value) => {
  adIncrement = value;
};
