import { createGlobalStyle } from 'styled-components'

export const themes = {
  default: {
    primaryColor: '#333954',
    secondaryColor: '#3EDBB2',
    borderColor: '#6CAEB2',
    pinkContrast: '#EF4280',
    pinkDarkContrast: '#5C3B5E',
    lightContrast: '#ffffff',
    greyContrast: '#d3d3d3',
    orangeContrast: '#FF8A5B',
    grey1: '#818181',
    lightGrayContrast: '#F9F9F9',
    darkGreyContrast: '#333954',
    darkBlueContrast: '#131b2a',
    darkBlackContrast: '#131b2b',
    darkestContrast: '#000000',
    linkColor: '#3EDBB2',
    hoverColor: '#EF4280',
    fontColor: '#ffffff',
    fontColorMedium: '#6C6C6C',
    fontColorLight: '#A5A5A5',
    fontFamily: {
      primaryFont: 'Graphik',
      secondaryFont: "'Source Serif Pro', sans-serif",
      serifBlack: "'Source Serif Pro Black', sans-serif",
      fontRubik:  "'Rubik', sans-serif",
    },
  },
  'worldofwomenssport.com': {
    secondaryColor: '#FF8A5B',
    borderColor: '#FF8A5B',
    linkColor: '#FF8A5B',
  },
};

export const spacing = {
  small: 12,
  border: 15,
  grid: 20,
  hero: 25,
};

export const width = {
  section: 1280,
  article: 955,
};

export const device = {
  mobileS: '(min-border: 320px)',
  mobileM: '(min-border: 375px)',
  mobileL: '(min-border: 425px)',
  tablet: '(min-border: 768px)',
  laptop: `(min-width: ${width.section + spacing.border * 2}px)`,
  maxLaptop: `(max-width: ${width.section + spacing.border * 2 - 1}px)`,
  maxTablet: '(max-border: 767px)',
}

export const menuHeight = '40px';
export const sectionColours = {
  breaking: '#FEEA00',
  world: '#1DAF6B',
  sport: '#FF8A5B',
  trending: '#F4464F',
  lifestyle: '#3DA5D9',
  celebrity: '#F092DD',
};

export const GlobalStyles = createGlobalStyle`
  body {
    background: #131b2a;
    //Rubic
    @font-face {
      font-family: 'Rubik';
      src: url('/fonts/Rubik-Regular.woff2') format('woff2');
      font-weight: normal;
      font-display: swap;
      font-style: normal;
    }
    @font-face {
      font-family: 'Rubik';
      src: url('/fonts/Rubik-Medium.woff2') format('woff2');
      font-weight: 600;
      font-display: swap;
      font-style: normal;
    }
    @font-face {
      font-family: 'Rubik';
      src: url('/fonts/Rubik-Bold.woff2') format('woff2');
      font-weight: bold;
      font-display: swap;
      font-style: normal;
    }
    //Source Serif Pro
    @font-face {
      font-family: 'Source Serif Pro';
      src: url('/fonts/SourceSerifPro-Regular.woff2') format('woff2');
      font-weight: normal;
      font-display: swap;
      font-style: normal;
    }
     @font-face {
      font-family: 'Source Serif Pro Black';
      src: url('/fonts/SourceSerifPro-Black.woff2') format('woff2');
      font-weight: normal;
      font-display: swap;
      font-style: normal;
    }
    // Graphik 
    @font-face {
      font-family: 'Graphik';
      src: url('/fonts/Graphik-Regular.woff2') format('woff2');
      font-weight: normal;
      font-display: swap;
      font-style: normal;
    }
    @font-face {
      font-family: 'Graphik';
      src: url('/fonts/Graphik-Medium.woff2') format('woff2');
      font-weight: 600;
      font-display: swap;
      font-style: normal;
    }
    @font-face {
      font-family: 'Graphik';
      src: url('/fonts/Graphik-Bold.woff2') format('woff2');
      font-weight: bold;
      font-display: swap;
      font-style: normal;
    }

    font-family: ${props => props.theme.fontFamily.primaryFont};
    color: ${props => props.theme.fontColor};
    -webkit-font-smoothing: antialiased;
  }

  /* reset button styles */
  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    cursor: pointer;
  }
  button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  & svg {
    transition: 0.2s all ease-in;
  }

  & button svg:hover,
  a svg:hover {
    opacity: 0.7;
  }

  /* override amp styles on dirty-amp pages */
  amp-sidebar {
    @media (min-width: 768px) {
      overflow: ${props => (props.cleanAmp ? 'inherit' : 'scroll !important')};
      -ms-overflow-style: none;
      width: auto;
    }
    @media (max-width: 400px) {
      max-width: 100%;
      width: 100%;
    }
     ::-webkit-scrollbar {
        display: none;
     }
  }

  amp-lightbox {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }

  /* style injected evolok adblocker */
    .modal-backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      background-color: #000;
      opacity: 0.5;
    }
    .ev .modal-dialog {
      width: 700px;
      top: 50%;
      transform: translateY(-50%);
    }
    .ev.modal {
      overflow: hidden;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1050;
      display: none;
      outline: 0;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .modal-content {
      overflow: hidden;
      border-radius: 0;
      padding-bottom: 20px;
      iframe {
        border: 0;
        max-width: 100%;
        height: 60px;
      }
      .col-md-12 {
        padding: 0;
      }
      .ev .row {
        margin-right: 0;
        margin-left: 0;
      }
      .site-title {
        margin-bottom: 20px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid ${props => props.theme.greyContrast};
      }
      .text-center {
        h3 {
          font-size: 20px;
        }
        p {
          font-size: 19px;
        }
      }
      p {
        font-size: 16px;
      }
      a, a:hover {
        color: ${props => props.theme.fontColor};
      }
      a:hover {
        opacity: 0.9;
      }
      .btn {
        background-color: ${props => props.theme.primaryColor};
        background-image: none;
        border: none;
        border-radius: 0;
        width: 215px;
        padding: 10px;
        margin: 10px 20px;
        color: ${props => props.theme.lightContrast};
        font-size: 14px;
        font-family: ${props => props.theme.fontFamily.primaryFont};
        flex-grow: 1;
      }
      .btn:hover {
        color: ${props => props.theme.lightContrast};
        background-color: ${props => props.theme.primaryColor};
        opacity: 0.9;
      }
    }
    .modal.fade .modal-dialog {
      transition: transform .3s ease-out;
      transform: translate(0,-110%);
    }
    .modal.in .modal-dialog {
      transform: translateY(-50%);
    }
    .qc-cmp-persistent-link {
      display: none ${props => (props.cleanAmp ? '' : '!important')};
    }
`;
